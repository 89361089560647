import {React, useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { Button, Input, Row, Col, Container, Toast, ToastHeader, ToastBody } from 'reactstrap';
import MainNavbar from './Navbar';

function Pickup() {
    const [customers, setCustomers] = useState([]);
    const [customersBackup, setCustomersBackup] = useState([]);
    const [err, setErr] = useState(null);
    const [selectedCustomer, setSelectedCutomer] = useState(null);
    const [success, setSuccess] = useState(null);    
    // TODO: Add some feedback from data fetch/ change endpoint to collect data
    const prevCustomer = useRef(null);

    useEffect(() => {
        setErr(" Getting customers...")
        axios.get("/get_customers").then((res) => {
            setCustomers(res.data);
            setCustomersBackup(res.data);
            setErr(null);
        }).catch((err) => {
            console.error("Error fetching customers:", err);
          });
    }, [])

    return(
        <>
            <MainNavbar/>
            <Container>
            {err? 
                <Row className='my-1'>
                    <Col>
                        <h6>{err}</h6>
                    </Col>
                </Row> : null}
                <Row>
                    <Col>
                        <Input id="textInput" type='text' placeholder='Search' onChange={(e) => {
                            if (e.target.value.length === 0) {
                                setCustomers(customersBackup);
                            } else {
                                const selectedValues = customersBackup.filter((elem) => elem.toLowerCase().includes(e.target.value.toLowerCase()));
                                setCustomers(selectedValues);
                                setSelectedCutomer(selectedValues[0]);
                            }

                        }}/>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Input id="inputSelect" type='select' onChange={(e) => {
                            setSelectedCutomer(e.target.value);
                            }
                        }>
                            {!selectedCustomer ? 
                                <option disabled={true} hidden={true} selected={true} >Select</option> : null}
                        {customers?.map((customer) => (<option key={customer} value={customer} >{customer}</option>))}
                        </Input>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col xs='12'>
                        <Button color={success ? 'success': selectedCustomer? 'warning' : 'secondary'} className='w-100 me-1' onClick={(e) => {
                            if (selectedCustomer) {
                                axios.post('/pickup', {'c': selectedCustomer});
                                window.history.replaceState(null, null, window.location.href);
                                prevCustomer.current = selectedCustomer;
                                setCustomers(customersBackup);
                                setSelectedCutomer(null);
                                document.getElementById("textInput").value = "";
                                setSuccess(true);
                                setTimeout(() => {setSuccess(false)}, 3000);
                            }
                        }
                        }>
                            {success ? 'PickedUp' : selectedCustomer ? selectedCustomer : 'Choose customer'}
                            {/* {(selectedCustomer == null)? "Pickup order": selectedCustomer} */}
                        </Button>
                    </Col>
                </Row>
                {success ? 
                    <Row className='fixed-bottom rounded '>
                        <Col>
                            <Toast className='position-relative start-50 translate-middle'>
                                <ToastHeader icon='success'>
                                    {`Processing`}
                                </ToastHeader>
                                <ToastBody>
                                    {`${prevCustomer.current}`}
                                </ToastBody>
                            </Toast>
                        </Col>
                    </Row>: null}
            </Container>
        </>
    )
}

export default Pickup;